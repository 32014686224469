<template>
    <section class="compras-listado-semanas container-fluid overflow-auto custom-scroll">
        <!-- year -->
        <div class="row">
            <div class="col-12 text-general text-center f-600 f-25">
                <p>Semanas {{ actualYear }}</p>
            </div>
        </div>
        <!-- listado semanas -->
        <div class="d-flex justify-content-center">
            <div class="contenedor-semanas d-inline-flex justify-content-center flex-wrap border py-1 br-4">
                <div 
                v-for="(data,i) in week_states" 
                :key="i" class="div-semana text-center f-12 cr-pointer" 
                @click="beforeGetPurchaseOrderDays(i + 1)">
                    <div v-if="data.tiene_ordenes" class="dot-notification" />
                    <div 
                    v-html="i + 1"
                    :class="`wh-24 rounded-circle d-middle-center
                    ${active == i + 1  ? 'active' : (data.tiene_ordenes ? 'some' : '')}`"
                    />
                </div>
            </div>
        </div>
        <!-- semana actual o seleccionada -->
        <div class="row my-4">
            <div class="col-12 text-5d text-center f-600 f-16">
                <p>{{ Object.keys(purchase_order_days)[0] | week}}</p>
            </div>
        </div>
        <!-- indicadores -->
        <div class="row justify-content-center my-3">
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-hand-extended" />
                    <p class="f-12">Proveedor</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-format-list-numbered" />
                    <p class="f-12">Cantidad de material</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-dolar" />
                    <p class="f-12">Valor de la orden</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-account" />
                    <p class="f-12">Cliente</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-progress-pencil" />
                    <p class="f-12">Borrador</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-history text-orange" />
                    <p class="f-12">Pendiente</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-circle-check text-info" />
                    <p class="f-12">Entrega parcial</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle-center">
                    <i class="icon-circle-check text-green" />
                    <p class="f-12">Entrega completa</p>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="row justify-content-center" style="gap: 1rem 0;">
            <div v-for="(day, idx) in Object.keys(purchase_order_days)" :key="idx" class="col-auto px-1">
                <div class="cont-days-info">
                    <div class="day-name bg-general br-5 f-14 d-middle-center text-white mb-3">
                        {{ day | helper-fecha('dddd') | ucfirst }} {{ day | helper-fecha('DD') | ucfirst }}
                    </div>
                    <div class="list-day p-2 border br-5">
                        <div v-for="(data,i) in Object.values(purchase_order_days)[idx]" :key="i" class="list-item bg-f5 border br-5 p-1 mb-2 cr-pointer" @click="ver(data.id)">
                            <div class="d-middle-center border-bottom mb-2">
                                <i :class="purchase_states[data.estado].icon || 'icon-information-outline'" />
                                <p class="f-12 f-500">Orden {{ data.id }}</p>
                            </div>
                            <div class="d-middle">
                                <i class="icon-hand-extended" />
                                <p class="f-12 f-500">{{ data.proveedor }}</p>
                            </div>
                            <div class="d-middle">
                                <i class="icon-format-list-numbered" />
                                <p class="f-12 f-500"> {{ data.numero_materiales }} </p>
                            </div>
                            <div class="d-middle">
                                <i class="icon-dolar" />
                                <p class="f-12 f-500">US {{ formatoMoneda(data.valor) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
    data(){
        return{
            active: moment().week(),
            actualYear: moment().year()
        }
    },
    computed: {
        ...mapGetters({
            purchase_order_days: 'compras/ordenes_compra/purchase_order_days',
            dates: 'compras/ordenes_compra/filter_days',
            week_states: 'compras/ordenes_compra/week_states',
            purchase_states: 'compras/ordenes_compra/purchase_states',
        }),
    },
    async created(){
        this.beforeGetPurchaseOrderDays(this.active)
        await Promise.all([
            this.getWeekStates({ 
                id: this.$route.params.id, 
                anio: this.actualYear
            }),
            this.getPurchaseOrderDays({
                id: this.$route.params.id, 
                payload: this.dates,
            })
        ]);
    },
    methods:{
        ...mapActions({
            getPurchaseOrderDays: 'compras/ordenes_compra/Action_get_purchase_order_days',
            setPurchaseOrderDaysByWeek: 'compras/ordenes_compra/Action_change_filter_dates',
            getWeekStates: 'compras/ordenes_compra/Action_get_week_states'
        }),
        beforeGetPurchaseOrderDays(week){
            this.active = week; 
            this.setPurchaseOrderDaysByWeek(week);
            this.getPurchaseOrderDays({
                id: this.$route.params.id, 
                payload: this.dates,
            })
        },
        ver(id){
            this.$router.push({ name: 'compras.ver.orden' , params: { id_orden : id } })
        }
    }

}
</script>

<style lang="scss" scoped>
.compras-listado-semanas{
    height: calc(100vh - 300px);
    .contenedor-semanas{
        max-width: 1161px;
        width: 1161px;
        //min-width: 1161px;
        .div-semana{
            width: 43px;
            min-width: 43px;
            max-width: 43px;
            position: relative;
            .dot-notification{
                width: 8px;
                height: 8px;
                background-color: #FF2449;
                border-radius: 50rem;
                position: absolute;
                left: 16px;
            }
            .active{
                background: var(--color-general);
                color: white;
            }
            .some{
                background: var(--color-db);
            }
            
        }
    }
    .cont-days-info{
        width: 150px;
        .day-name{
            //width: 150px;
            height: 35px;
        }
    }
    .day-name{
        width: 150px;
        height: 35px;
    }
    .list-day{
        min-height: 141px;
    }
}
</style>